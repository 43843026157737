require("@rails/ujs").start()
import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', function(){
    if(document.querySelector('.community-embed .swiper-container') != null) {
        const swiper = new Swiper('.community-embed .swiper-container', {
            direction: 'horizontal',
            freeMode: true,
            spaceBetween: 20,
            slidesPerView: 5,
            breakpoints: {
                320: {
                    slidesPerView: 2.3,
                },
                640: {
                    slidesPerView: 5.3,
                }
            },
        });
    }

    console.log("X")
    document.querySelector('.open-menu').addEventListener('click', function() {
        console.log("X")
        document.getElementById("myNav").style.height = "100%";
    });
    document.querySelector('.close-menu').addEventListener('click', function() {
        document.getElementById("myNav").style.height = "0%";
    });

    if(document.querySelector('.channel-images .swiper-container') != null) {
        const swiper = new Swiper('.channel-images .swiper-container', {
            direction: 'horizontal',
            freeMode: true,
            spaceBetween: 20,
            slidesPerView: 3.2,
            breakpoints: {
                320: {
                    slidesPerView: 1.3,
                },
                640: {
                    slidesPerView: 3.2,
                }
            },
        });
    }


/*    if(document.querySelector('.swiper-container') != null) {
        const swiper = new Swiper('.swiper-container', {
            direction: 'horizontal',
            freeMode: true,
            spaceBetween: 20,
            slidesPerView: 2.3,
            breakpoints: {
                320: {
                    slidesPerView: 1.3,
                },
                640: {
                    slidesPerView: 2.3,
                }
            },
        });
    }*/

});
